<template>
    <van-empty
        image="search"
        v-if="linePointList.length == 0 && load"
        description="暂无数据"
    />
    <div class="point-container" v-else>
        <div v-for="(item, index) in linePointList" :key="index">
            <div class="comment-container">
                <div class="comment-logo">
                    <van-image
                        width="50px"
                        height="50px"
                        radius="50px"
                        :src="item.avator"
                    />
                    <div class="comment-name">
                        {{ item.uname }}
                    </div>
                </div>
                <div class="comment-content">
                    <div>{{ item.commentText }}</div>
                    <van-image
                        width="250px"
                        height="120px"
                        radius="0"
                        :src="item.imgUrl"
                        class="u-m-t-30"
                        fit="contain"
                    />
                    <div class="point-title">{{ item.pointAddress }}</div>
                    <div class="comment-time u-m-t-10">
                        {{ item.createdAt }}
                    </div>
                </div>
            </div>
            <van-divider hairline style="margin: 0" />
        </div>
    </div>
</template>

<script>
import { getMyComments } from "@/service";
export default {
    data() {
        return {
            load: false,
            linePointList: [],
        };
    },
    mounted() {
        this.getMyComments();
    },
    methods: {
        async getMyComments() {
            const params = {
                pageNo: 1,
                pageSize: 1000,
                commonId: "",
            };
            const { data } = await getMyComments(params);
            this.load = true;
            this.linePointList = data ? data.list : [];
        },
    },
};
</script>

<style lang="scss" scoped>
@import "@/style/mixin";
@import "@/style/_varibles";

.point-container {
    padding: 30px;
    @include wh(100%, auto);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    .point-title {
        @include sc(24px, $--color-text-regular);
        text-align: left;
        padding-top: 10px;
    }
    .point-item {
        @include fj(center);
        width: 48%;
        text-align: center;
        margin-top: 20px;
        border-radius: 20px;
        box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.17);
        height: 400px;
        position: relative;
    }
    .comment-container {
        padding: 30px 20px 30px;
        display: flex;
        align-items: flex-start;
        .comment-name {
            @include sc(28px, $--color-text-regular);
            text-align: center;
        }
        .comment-content {
            @include sc(28px, $--color-text-regular);
            padding-left: 20px;
            flex: 1;
            .comment-time {
                @include sc(22px, $--color-text-secondary);
            }
        }
    }
}
</style>